import { useFormState } from 'react-hook-form'
import { Button, ButtonProps } from './ui/button'

type FormSubmitButtonProps = Omit<ButtonProps, 'type' | 'disabled'>

export function FormSubmitButton({ children, ...buttonProps }: FormSubmitButtonProps) {
  const { isValid, isSubmitting } = useFormState()
  return (
    <Button disabled={!isValid || isSubmitting} type="submit" {...buttonProps}>
      {children}
    </Button>
  )
}
